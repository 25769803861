import { LeoProModal } from './LeoProModal'
import styled from 'styled-components'
import * as analytics from '../../lib/analytics'
import { BUY_LEO, CONTACT_SALES } from '@/lib/config.ts'

export const LinkButton = styled.a`
  background: var(--purple-100);
  color: var(--leo-white);
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  width: 14rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`

export const OrButton = styled.div`
  font-size: 3.2rem;
  font-weight: bold;
`

interface ModalWithButtonsProps {
  showModal: boolean
  setShowModal: (v: boolean) => void
  title?: string
  analyticsMessage?: string
  hideCloseButton?: boolean
}

export const ModalWithButtons = ({
  title,
  setShowModal,
  showModal,
  analyticsMessage,
  hideCloseButton,
}: ModalWithButtonsProps) => {
  return (
    <LeoProModal
      modalIsOpen={showModal}
      setModalIsOpen={setShowModal}
      title={title}
      analyticsMessage={analyticsMessage}
      hideCloseButton={hideCloseButton}
    >
      <LinkButton
        onClick={() => {
          analytics.track('Buy Leo Now Clicked', {})
        }}
        href={BUY_LEO}
        target="_blank"
      >
        Buy Leo Now
      </LinkButton>

      <OrButton>OR</OrButton>

      <LinkButton
        onClick={() => {
          analytics.track('Contact Sales Clicked', {})
        }}
        href={CONTACT_SALES}
        target="_blank"
      >
        Contact Sales
      </LinkButton>
    </LeoProModal>
  )
}
