import { IpcRendererEvent } from 'electron'
import { onInputSubmitted, onReceivedData, IpcCallbackWithData, onReceivedDataV2 } from '@/lib/app'

let isHandlerRegistered = false

export const registerIpcHandlers = (
  handleReceivedData: IpcCallbackWithData,
  handleInputSubmitted: (event: IpcRendererEvent, text: string) => void,
  handleReceivedDataV2: IpcCallbackWithData
) => {
  if (!isHandlerRegistered) {
    onReceivedData(handleReceivedData)
    onInputSubmitted(handleInputSubmitted)
    onReceivedDataV2(handleReceivedDataV2)
    isHandlerRegistered = true
  }
}
