import { useEffect, useCallback, useRef } from 'react'
import { openInputWindow } from '@/lib/app'
import { CadDataPostRequestAssembly, useCadData } from '@/api/cad-data'
import { useSessionStore } from '@/store/useSessionStore'
import { useChat } from '@/store/useChat'
import * as analytics from '@/lib/analytics'
import { IpcRendererEvent } from 'electron'
import { registerIpcHandlers } from './ipcHandlerManager'
import { popToastErrorMessage } from '@/api/utils.ts'
import { defaultAndUnrealisticErrorMessage } from '@/constants/msgEnums.ts'

const IntegrationInput = () => {
  const { sessionNum } = useSessionStore(store => store)
  const { setIsLoading, isLoading, messages, setMessages } = useChat(store => store)

  const { postCadData } = useCadData()

  const geometryData = useRef(null)

  const assemblyData = useRef<CadDataPostRequestAssembly | null>(null)

  const handleReceivedData = useCallback((_event: IpcRendererEvent, data: any) => {
    geometryData.current = data
    openInputWindow()
  }, [])

  const handleReceivedDataV2 = useCallback((_event: IpcRendererEvent, data: CadDataPostRequestAssembly | Error) => {
    if (data instanceof Error) {
      popToastErrorMessage(data.message)
      return
    }

    assemblyData.current = data
    openInputWindow()
  }, [])

  const handleInputSubmitted = useCallback(
    async (_event: IpcRendererEvent, text: string) => {
      const content: any = { userMessage: text, geometry: geometryData.current }
      const currentMessages = useChat.getState().messages

      setIsLoading(true)
      setMessages([...currentMessages, { sender: 'user', text }])

      if (isLoading) return

      try {
        const data = await postCadData(content)
        setIsLoading(false)

        const result = {
          messages: [...currentMessages, ...data.result.messagesList],
          isValid: true,
        }

        analytics.track('Cad Data Received', { isValid: result.isValid })

        if (result.isValid) {
          setMessages([...result.messages])
        }
      } catch (e) {
        setIsLoading(false)
        popToastErrorMessage(defaultAndUnrealisticErrorMessage)
        setMessages(currentMessages)
      }
    },
    [messages, isLoading]
  )

  useEffect(() => {
    registerIpcHandlers(handleReceivedData, handleInputSubmitted, handleReceivedDataV2)
  }, [handleReceivedData, handleInputSubmitted, sessionNum, handleReceivedDataV2])

  return null
}

export default IntegrationInput
