// General
import { extractPrNumber } from '@/api/utils.ts'

const env = import.meta.env

// Mixpanel
export const MIXPANEL_TOKEN = env.VITE_MIXPANEL_TOKEN || '297bc56853b7e96b7e476a08018e54e2'

//SENTRY DEV
// export const SENTRY_DSN =
//   "https://831a0eb73e43689a46ac8b790c73a72e@o4505905649090560.ingest.sentry.io/4505905746477056";
// export const SENTRY_ENVIRONMENT = "develop";

export const SENTRY_DSN = env.VITE_SENTRY_DSN
export const SENTRY_ENVIRONMENT = env.VITE_SENTRY_ENVIRONMENT

export const BASE_URL = env.VITE_API_ENDPOINT || 'https://api.getleo.ai'

export const WEBSITE_URL = 'https://www.getleo.ai/'

export const PRIVACY_POLICY_URL = 'https://www.getleo.ai/privacy'

export const SLACK_URL = 'https://join.slack.com/t/leoaipublic/shared_invite/zt-25ktda6yb-xJA~q5wf1hP3bAfNCFus9Q'

export const SUPPORT_URL = 'support@getleo.ai'

export const BUY_LEO = env.BUY_LEO || 'https://buy.stripe.com/8wMg1m8Fj7zS5LqbIM'

export const CONTACT_SALES = env.CONTACT_SALES || 'https://www.getleo.ai/contact-sales'

//todo this is a temp fix until we solve the issue with the env variables
const prNumber = extractPrNumber(window.location.href)
export const DOWNLOAD_APP_LINK = prNumber
  ? `https://storage.googleapis.com/installer-version-distribution-public/windows/preview/pr-${prNumber}/Leo-preview.exe`
  : window.location.href.includes('beta')
    ? 'https://storage.googleapis.com/installer-version-distribution-public/windows/latest/Leo-beta.exe'
    : env.DOWNLOAD_APP_LINK ||
      'https://storage.googleapis.com/installer-version-distribution-public/windows/latest/Leo.exe'

export const FILE_SYNC_ENABLED = env.FILE_SYNC_ENABLED || false
